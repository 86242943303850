@font-face {
  font-family: 'Pretendard Black';
  font-style: normal;
  src: url('./Pretendard-Black.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard Bold';
  font-style: normal;
  src: url('./Pretendard-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard ExtraBold';
  font-style: normal;
  src: url('./Pretendard-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard ExtraLight';
  font-style: normal;
  src: url('./Pretendard-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard Light';
  font-style: normal;
  src: url('./Pretendard-Light.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard Medium';
  font-style: normal;
  src: url('./Pretendard-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  src: url('./Pretendard-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard SemiBold';
  font-style: normal;
  src: url('./Pretendard-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard Thin';
  font-style: normal;
  src: url('./Pretendard-Thin.woff') format('woff');
}
